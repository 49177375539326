<template>
  <div class="home">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
  import destinationService from '@/services/DestinationService'
  import router from "@/router";

  export default {
    name: 'Home',
    components: {
    },
    methods: {
      handleCallback(code, state){
        destinationService.handleCallback(code, state).then(response => {
          console.log(response)
          router.push("/connections");
        }).catch(error => {
          console.log('error', error) // create notification-toaster for user error
        })
      }
    },
    mounted(){
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      if (code && state) {
        this.handleCallback(code, state)
      }
    }
  }
</script>